import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import chevronSvg from '../../assets/chevron.svg';
import { TEXT_COLOR, HOVER_COLOR, SECONDARY_COLOR } from '../../constants';

export const SIDE_PADDING = 30;
const BORDER_RADIUS = 33;

const Container = styled.div`
  width: 100%;
  max-width: 650px;
  border: ${SECONDARY_COLOR} solid 2px;
  border-radius: ${BORDER_RADIUS}px;
  background: white;
  margin-bottom: 14px;
  position: relative;
`;

const Question = styled.button`
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 500;
  font-size: 18pt;
  border: none;
  border-radius: ${BORDER_RADIUS}px;
  background: white;
  width: 100%;
  text-align: left;
  padding: 18px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${TEXT_COLOR};
  cursor: pointer;

  &:focus {
    background: white;
  }
`;

const Answer = styled.div`
  ${({ height }) => `
    height: ${height}px;
  `}
  overflow: hidden;
  will-change: height;
  transition: height 200ms ease;

  font-family: mr-eaves-modern, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16pt;

  a {
    text-decoration-thickness: 1px;
    text-underline-offset: 1px;
    color: ${TEXT_COLOR};
    &:hover {
      color: ${HOVER_COLOR};
    }
  }
`;

const Chevron = styled.img`
  width: 25px;
  pointer-events: none;
  padding-left: 8px;

  will-change: transform;
  transition: transform 200ms ease;
  transform-origin: 50% 50%;
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const FAQBox = ({ question, answer, isExpanded, onClick }) => {
  const answerRef = useRef(null);
  const [answerHeight, setAnswerHeight] = useState(0);

  // calculates the expanded area height for animation purposes
  useEffect(() => {
      if (isExpanded) {
          const refHeight = answerRef && answerRef.current && (answerRef.current.scrollHeight || 0);
          setAnswerHeight(refHeight);
      } else {
          setAnswerHeight(0);
      }
  }, [isExpanded]);

  return (
    <Container>
      <Question onClick={onClick}>
        {question}
        <Chevron src={chevronSvg} isExpanded={isExpanded} />
      </Question>
      <Answer
        ref={answerRef}
        isExpanded={isExpanded}
        height={answerHeight}
      >
        {answer}
      </Answer>
    </Container>
  );
};
