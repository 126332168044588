import styled from 'styled-components';

import { useState } from 'react';
import { MerkleTree } from 'merkletreejs';
import {
  useAccount,
} from 'wagmi';
import { keccak256 } from "ethers/lib/utils"
import { ethers, BigNumber } from 'ethers';
import speechImage from './speech.jpg';
import ABI from '../../contract.json';
import { CloseIcon } from '../icons/CloseIcon';

import { ConnectButton } from '../ConnectButton';
import { PC_CONTRACT_ADDRESS } from '../../constants';
import { WHITELIST } from './whitelist';
const TOTAL_WITCHES = 500;


const MintBoxContainer = styled.div`
overflow: hidden;
  z-index: 1001;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 500px;
  background: white;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

const SpeechContent = styled.div`
  position: absolute;
  bottom: 80px;
display: flex;
width: 200px;
text-align: center;
font-size: 19px;
height: 80px;
justify-content: center;
align-items: center;
margin-left: 208px;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
`;

const MintedNum = styled.div`
  font-weight: 600;
`;

const SpeechImage = styled.img`
  position: absolute;
  bottom: 0;
  width: 450px;
`;

const Title = styled.h1`
background: #ffd8d8;

  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
  font-family: 'Mellisa', Helvetica, Trebuchet MS, sans-serif;
  font-size: 30pt;
  font-weight: normal;
  padding-top: 6px;
`;

const ModalBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  opacity: 70%;
  z-index: 1000;
`;

const Message = styled.div`
  position: absolute;
  width: full;
  right: 10px;
  bottom: 10px;
  font-size: 15px;
  width: 260px;
  text-align: right;
`;

const SummonButton = styled.button`
font-family: mr-eaves-modern, sans-serif;
font-weight: 500;
font-size: 18pt;
border: none;
border-radius: 15px;
background: white;
text-align: left;
padding: 10px 20px;
display: flex;
align-items: center;
justify-content: space-between;
cursor: pointer;
border: #5f585f solid 2px;
margin-left: 5px;
margin-right: 5px;
background: #fff2f2;
@media screen and (max-width: 600px) {
  margin-bottom: 10px;
}

&:hover {
  background: #ffe7e7;
}

&:disabled {
  border: lightGray solid 2px;
  cursor: not-allowed;
}

`;

const Buttons  = styled.div`
display: flex;
flex-direction: row;
margin-top: 20px;

@media screen and (max-width: 600px) {
  flex-direction: column;
}
`;

const CloseButton = styled.button`
border: 0;
background: none;
padding: 0;
margin: 0;
cursor: pointer;
position: absolute;
right: 10px;
top: 10px;
color: white;

background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;

.svg {
  fill: white;
}
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
`;
//
// const PriceWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `
//
//  const Price = styled.div`
//   font-size: 18px;
//   text-align:center;
//   font-weight: 500;
//  `;

export const MintBox = ({ isSaleActive, isPresaleActive, totalMinted, setTotalMinted, numMinted, setNumMinted, close }) => {

  const [isMinted, setIsMinted] = useState(false);
  const [isMintError, setIsMintError] = useState(false);
  const [initiatedConfirmation, setInitiatedConfirmation] = useState(false);
  const [transactionStarted, setTransactionStarted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { isConnected, address } = useAccount();

const soldout = totalMinted >= TOTAL_WITCHES;

function generateMerckleProof() {
   // generate tree
   const leaves = WHITELIST.map(v => keccak256(ethers.utils.getAddress(v)))
   // const leaves = WHITELIST.map(v => keccak256(v))
   const tree = new MerkleTree(leaves, keccak256, { sort: true })
   // const root = tree.getHexRoot()

   // const leaf = keccak256(address)
   const leaf = keccak256(ethers.utils.getAddress(address))
   const proof = tree.getHexProof(leaf)

   return proof;
 }

 async function handlePresaleMint(amount) {
   resetStates();
   let provider;
     try {
       if (window.ethereum) {
         provider = new ethers.providers.Web3Provider(window.ethereum);
       } else {
         provider = new ethers.providers.AlchemyProvider(null, 'fQU6y180n-3YPIT3t0ugVDYOGpkTQzWI');
       }
       const signer = provider.getSigner();
       const contract = new ethers.Contract(
         PC_CONTRACT_ADDRESS,
         ABI,
         signer
       );

       let cost = 0.025;
       if (amount === 2) {
         cost = 0.05;
       } else if (amount === 3) {
         cost = 0.075;
       }

       const proof = generateMerckleProof();
       contract.witchListMint(
         proof,
         BigNumber.from(amount),
         {
           value: ethers.utils.parseEther(cost.toString())
         }
       ).then((response) => {
         resetStates();
         setTransactionStarted(true);

         response.wait().then(({ status }) => {
           resetStates();
           if (status === 1) {
             setIsMinted(true);
             setTotalMinted(totalMinted += amount);
             setNumMinted(numMinted += amount);
           } else {
             setIsMintError(true);
           }
         }).catch(err => {
           console.log('error waiting for transaction response');
           setIsMintError(true);
         });
       }).catch((error) => {
         handleError(error);
       });
     } catch (err) {
       handleError(err);
     }

 };

 function handleError(error) {
   console.log({ error })
   let message = 'unknown error';
   if (error && error.message) {
     message = error.message.substring(0, 80);
   }
   if (error && error.reason) {
     message = error.reason.substring(0, 80);
   }
   resetStates();
   setErrorMessage(message);
 }

 function resetStates() {
   setIsMinted(false);
   setIsMintError(false);
   setErrorMessage(null);
   setInitiatedConfirmation(false);
   setTransactionStarted(false);
   setErrorMessage(null);
 }

 async function handlePublicMint(amount) {
   resetStates();
   if (window.ethereum) {
     try {
       const provider = new ethers.providers.Web3Provider(window.ethereum);
       const signer = provider.getSigner();
       const contract = new ethers.Contract(
         PC_CONTRACT_ADDRESS,
         ABI,
         signer
       );

       let cost = 0.025;
       if (amount === 2) {
         cost = 0.05;
       } else if (amount === 3) {
         cost = 0.075;
       }

       contract.mint(
         BigNumber.from(amount),
         {
           value: ethers.utils.parseEther(cost.toString())
         }
       ).then((response) => {
         resetStates();
         setTransactionStarted(true);

         response.wait().then(({ status }) => {
           resetStates();
           if (status === 1) {
             setIsMinted(true);
             setTotalMinted(totalMinted += amount);
            setNumMinted(numMinted += amount);
           } else {
             setIsMintError(true);
           }
         }).catch(err => {
           console.log('error waiting for transaction response');
           setIsMintError(true);
         });
       }).catch((error) => {
         handleError(error);
       });
     } catch (err) {
       handleError(err);
     }
   } else {
     resetStates();
     setErrorMessage('No wallet');
   }
 };

  const isOnWl = address && WHITELIST.some(wl => wl.toLowerCase() === address.toLowerCase());
  const ableToMint = (isPresaleActive && address && isOnWl) || isSaleActive;
  const noMoreMints = isPresaleActive && numMinted >= 3;
  const disabled = initiatedConfirmation || transactionStarted || soldout || !ableToMint || noMoreMints;
  console.log({ initiatedConfirmation, transactionStarted, errorMessage, disabled, ableToMint, isMinted, isMintError})

  return (
    <>
    <ModalBg onClick={close} />
    <MintBoxContainer>
    <CloseButton title="close" onClick={close}>
      <CloseIcon />
    </CloseButton>
      <Title>Summon Your Coven</Title>
      <Bottom>
      <MintedNum>
        {`${totalMinted}/${TOTAL_WITCHES} summoned`}
      </MintedNum>
      {isConnected ? (
        <Buttons>
          <SummonButton
            disabled={disabled}
            onClick={() => isPresaleActive ? handlePresaleMint(1) : handlePublicMint(1)}
          >
            Summon 1
          </SummonButton>
          <SummonButton
            disabled={disabled}
            onClick={() => isPresaleActive ? handlePresaleMint(2) : handlePublicMint(2)}
          >
            Summon 2
          </SummonButton>
          <SummonButton
            disabled={disabled}
            onClick={() => isPresaleActive ? handlePresaleMint(3) : handlePublicMint(3)}
          >
            Summon 3
          </SummonButton>
        </Buttons>
      ) : (
        <ConnectButton showMintboxDesign={true} />
      )}
      </Bottom>
      <ImageContainer>
        <SpeechImage
          src={speechImage}
        />
        <SpeechContent>
        <div style={{ fontWeight: '500'}}>
          {soldout ? (
            <div>Sold out!</div>
          ) : noMoreMints ? (
            <div>You've minted the max amount for Witchlist. Please wait for public at 9pm PST!</div>
          ) : initiatedConfirmation ? (
            <div>Please confirm the transaction</div>
          ) : transactionStarted ? (
            <div>Your coven is summoning!</div>
          ) : isMinted ? (
            <div>Your coven has summoned successfully!</div>
          ) : isMintError ? (
            <div>Oh no! Something went wrong with the transaction.</div>
          ) : errorMessage ? (
            <div>{`Oh no! Mint error: ${errorMessage}`}</div>
          ) : address && !isOnWl && isPresaleActive ? (
            <div>You are not on the Witchlist! Please wait until 9pm PST to mint public.</div>
          ) : (
            <div>
                0.025e per WITCH. <br /> No roadmap or utility.<br /> Art and cuteness only!
            </div>
          )}
          </div>
        </SpeechContent>
      </ImageContainer>
      <Message>There is currently an issue with Rainbow Wallet. We are looking into it!</Message>
    </MintBoxContainer>
    </>
  );
};
