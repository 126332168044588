import styled from 'styled-components';

import { Title } from '../Title';
import {
  TEXT_COLOR,
  HOVER_COLOR,
  TABLET_HEIGHT,
  TABLET_WIDTH,
  SNEAK_1_IMAGE,
  SNEAK_2_IMAGE,
  SNEAK_3_IMAGE,
  MAGE_VID,
  MAGE_VID_POSTER,
  SECONDARY_COLOR,
} from '../../constants';

const AboutContainer = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fffcfb;
  z-index: 200;
  box-shadow: 0 0 30px 0 rgba(0 0 0 / 20%);
  padding: 0 20px;

  a {
    text-decoration-thickness: 1px;
    text-underline-offset: 1px;
    color: ${TEXT_COLOR};
    &:hover {
      color: ${HOVER_COLOR};
    }
  }

  min-height: 100vh;

  @media screen and (min-height: ${TABLET_HEIGHT}px) and (min-width: ${TABLET_WIDTH}px) {
    height: 700px;
    min-height: 700px;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 80px;
`;

const Description = styled.p`
  max-width: 720px;
  text-align: center;
  padding: 15px 0;
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16pt;

  @media screen and (max-width: 700px) {
    margin: 0;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5px 0;
`;

const Sneak = styled.img`
  width: 170px;
  height: 170px;
  margin: 5px;
  border-radius: 20px;
  border: 2px solid ${SECONDARY_COLOR};

  @media screen and (max-width: 795px) {
    width: 140px;
    height: 140px;
  }

  @media screen and (max-width: 690px) {
    width: 110px;
    height: 110px;
  }

  @media screen and (max-width: 560px) {
    width: 140px;
    height: 140px;
  }

  @media screen and (max-width: 350px) {
    width: 110px;
    height: 110px;
  }
`;

const SneakRow = styled.div`
`;

export const About = ({ innerRef }) => (
  <AboutContainer id="about" ref={innerRef}>
    <InnerContainer>
      <Title title="About" />
      <Description>
        Pocket Coven is a delightful collection of pocket-sized WITCHES that dwell on the Ethereum blockchain. These tiny companions originate from the mini wilds - a small magical nook within the weird wilds of <a href="https://twitter.com/crypto_coven" target="_blank" rel="noreferrer" title="opens twitter in a new tab">Crypto Coven</a>. This community-driven project is full of joyful wonders, including an one-of-a-kind animated WITCH! There is no roadmap or utility. Art and cuteness only!
      </Description>
      <ImagesContainer>
        <SneakRow>
          <Sneak src={SNEAK_1_IMAGE} />
          <Sneak src={SNEAK_2_IMAGE} />
        </SneakRow>
        <SneakRow>
          <Sneak src={SNEAK_3_IMAGE} />
          <Sneak
            as="video"
            poster={MAGE_VID_POSTER}
            loop
            playsInline
            muted
            autoPlay
          >
            <source src={MAGE_VID} type="video/mp4" />
          </Sneak>
        </SneakRow>
      </ImagesContainer>
    </InnerContainer>
  </AboutContainer>
);
