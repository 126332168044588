import styled from 'styled-components';

import {
  TEXT_COLOR,
  HOVER_COLOR,
} from '../../constants';

export const Link = styled.a`
  position: relative;
  text-decoration: none;
  padding: 0 17px;
  //  z-index: 998;
  font-family: 'BeMine', sans-serif;
  font-size: 20pt;
  color: ${TEXT_COLOR};
  letter-spacing: 1px;

  font-size: 21pt;
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 300;
  letter-spacing: -1px;

  &:hover {
    color: ${HOVER_COLOR};
  }


  ${({ disabled, isSideMenu }) => disabled && `
    cursor: not-allowed;
    color: #434343;
    &:hover {
      color: #434343;
    }

    ${isSideMenu ? `
      color: #9b9b9b;
      &:hover {
        color: #9b9b9b;
      }
    ` : `
      @media screen and (max-width: 1066px) {
        display: none;
      }
    `}
  `}
`;

export const Subscript = styled.sup`
  position: absolute;
  font-size: 13pt;
  margin-left: -5px;
  margin-top: -6px;
  color: #9b9b9b;
`;

export const NavLink = ({ disabled, title, link }) => (
  <Link disabled={disabled} href={link}>
    {title}
    {disabled && <Subscript>soon</Subscript>}
  </Link>
);
