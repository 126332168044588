import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { PlusIcon } from '../icons/PlusIcon';
import { MinusIcon } from '../icons/MinusIcon';

import { ExpandedControls } from './ExpandedControls';
import {
  MUSIC_Z_INDEX,
  PIANO_PAUSED_IMAGE,
  PIANO_PLAYING_A_IMAGE,
  PIANO_PLAYING_B_IMAGE,
  PIANO_PLAYING_C_IMAGE,
} from '../../constants';
import { TRACKS } from './constants';

const IMAGE_WIDTH = 90;

const PIANO_IMAGES = [
  PIANO_PLAYING_A_IMAGE,
  PIANO_PLAYING_C_IMAGE,
  PIANO_PLAYING_B_IMAGE,
];

const Container = styled.div`
  background: white;
  position: fixed;
  left: 20px;
  bottom: 20px;
  display: flex;
  z-index: ${MUSIC_Z_INDEX};
  height: 60px;
  box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 28%);
  border-radius: 10px;
  align-items: center;

  will-change: width;
  transition: width 100ms ease;
`;

const Info = styled.div`
  will-change: width, opacity;
  transition: width 200ms ease, opacity 300ms ease-in;
  overflow: hidden;

  ${({ isHidden }) => `
    width: ${isHidden ? 0 : 120}px;
    opacity: ${isHidden ? 0 : 100}%;
  `}
`;

const ImageContainer = styled.button`
  position: relative;
  width: ${IMAGE_WIDTH}px;
  align-self: flex-end;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const Image = styled.img`
  width: ${IMAGE_WIDTH}px;
  position: absolute;
  bottom: 0;
  left: 0;
  ${({ isVisible }) => `
    opacity: ${isVisible ? 1 : 0};
  `}
  // filter: drop-shadow(-1px 3px 2px rgb(0 0 0 / 20%));
`;

const SongName = styled.div`
  font-weight: 500;
  font-size: 14pt;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 15px;
`;

const ArtistName = styled.div`
  font-size: 12pt;
  padding-left: 15px;
`;

const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #d4d2d2;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  position: absolute;
  right: -20px;
  top: -20px;
  cursor: pointer;
`;

export const MusicPlayer = () => {
  const [trackIndex, setTrackIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasBeenExpanded, setHasBeenExpanded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [pianoIndex, setPianoIndex] = useState(0);

  // TODO randomize

  const currentTrack = TRACKS[trackIndex];
  const trackTotalNum = TRACKS.length;

  const audioRef = useRef([new Audio(currentTrack.src)]);
  const pianoIntervalRef = useRef();

  useEffect(() => {
    return () => {
      clearInterval(pianoIntervalRef.current);
    }
  }, []);

  useEffect(() => {
    if (audioRef.current[trackIndex]) {
      if (isPlaying) {
        audioRef.current[trackIndex].play();
        startTimer();
      } else {
        audioRef.current[trackIndex].pause();
        clearInterval(pianoIntervalRef.current);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    if (!audioRef.current[trackIndex]) {
      audioRef.current[trackIndex] = new Audio(currentTrack.src);
    }

    if (isPlaying) {
      audioRef.current[trackIndex].play();
      startTimer();
    }

    return () => {
      cleanupCurrentTrack();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackIndex]);

  const cleanupCurrentTrack = () => {
    audioRef.current[trackIndex].pause();
    audioRef.current[trackIndex].currentTime = 0;
  }

  const startTimer = () => {
    clearInterval(pianoIntervalRef.current);

    pianoIntervalRef.current = setInterval(() => {
      setPianoIndex(prevIndex => (prevIndex + 1)%3);
      if (audioRef.current[trackIndex].ended) {
        goNextTrack();
      }
    }, [TRACKS[trackIndex].intervalMs]);
  }

  const goPrevTrack = () => {
    cleanupCurrentTrack();

    if (trackIndex === 0) {
      setTrackIndex(trackTotalNum - 1);
    } else {
      setTrackIndex(trackIndex - 1);
    }
  };

  const goNextTrack = () => {
    cleanupCurrentTrack();

    if (trackIndex === trackTotalNum - 1) {
      setTrackIndex(0);
    } else {
      setTrackIndex(trackIndex + 1);
    }
  };

  const handlePlayPauseClick = () => {
    if (!hasBeenExpanded) {
      setHasBeenExpanded(true);
      setIsExpanded(true);
    }
      setIsPlaying(!isPlaying);
  };

  const handleCollapseClick = () => {
    setIsExpanded(!isExpanded);
  }

  return (
    <Container>
      <ImageContainer
        onClick={handlePlayPauseClick}
        title={isPlaying ? 'pause music' : 'play music'}
      >
        <Image src={PIANO_PAUSED_IMAGE} isVisible={!isPlaying} />
        {PIANO_IMAGES.map((src, index) => (
          <Image
            key={index}
            src={src}
            isVisible={isPlaying && pianoIndex === index}
          />
        ))}
      </ImageContainer>
      <Info isHidden={!isExpanded}>
        <SongName title={currentTrack.title}>
          {currentTrack.title}
        </SongName>
        <ArtistName>Oneul</ArtistName>
      </Info>
      <ExpandedControls
        isPlaying={isPlaying}
        isExpanded={isExpanded}
        onPrevClick={goPrevTrack}
        onNextClick={goNextTrack}
        onPlayPauseClick={handlePlayPauseClick}
      />
      {hasBeenExpanded && (
        <ExpandButton
          onClick={handleCollapseClick}
          title={isExpanded ? 'collapse the music player' : 'expand the music player'}
        >
          {isExpanded ? (
            <MinusIcon size={20} />
          ) : (
            <PlusIcon size={20} />
          )}
        </ExpandButton>
      )}
    </Container>
  );
};
