import styled from 'styled-components';

import { CAULDRON_IMAGE } from '../../constants';

const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
  font-size: 14pt;
  padding: 20px 0;

  @media screen and (max-width: 600px) {
    justify-content: flex-end;
  }
`;

const Copyright = styled.div`
  font-size: 13pt;
`;

const CauldronImage = styled.img`
  height: 20px;
  padding-left: 6px;
  padding-right: 6px;

  @media screen and (max-width: 600px) {
    padding-right: 20px;
  }
`;

export const Footer = () => (
  <Container>
    <Copyright>
      © 2022 Pocket Coven
    </Copyright>
    <CauldronImage loading="lazy" src={CAULDRON_IMAGE} />
    <Copyright>
      This is jojochuu's personal project and is not affiliated with her employer
    </Copyright>
  </Container>
);
