import styled from 'styled-components';

import { Link } from './NavLink';
import { TwitterIcon } from '../icons/TwitterIcon';
import { MENU_Z_INDEX, TWITTER_URL } from '../../constants';

const Container = styled.div`
  height: 100%;
  min-height: 500px;
  width: 100%;
  background: white;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${MENU_Z_INDEX};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  ${({ isExpanded }) => `
    display: ${isExpanded ? 'flex' : 'none'};
  `}
`;

const StyledLink = styled(Link)`
  padding: 10px;
  font-size: 22pt;
`;

const Twitter = styled.a`
  width: 30px;
  height: 30px;
  padding-top: 15px;
`;

export const SideMenu = ({ isExpanded, onClose }) => {

  return (
    <Container isExpanded={isExpanded}>
      <StyledLink href="#home" onClick={onClose}>Home</StyledLink>
      <StyledLink href="#about" onClick={onClose}>About</StyledLink>
      <StyledLink href="#faq" onClick={onClose}>FAQ</StyledLink>
      <StyledLink href="#team" onClick={onClose}>Team</StyledLink>
      <Twitter
        href={TWITTER_URL}
        target="_blank"
        title="open twitter in a new tab"
        onClick={onClose}
      >
        <TwitterIcon />
      </Twitter>
    </Container>
  );
};
