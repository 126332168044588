import { CDN_URL_BASE } from '../../constants';

export const TRACKS = [
  {
    title: 'My Hope Is Built On Nothing Less',
    src: `${CDN_URL_BASE}MyhopeIsBuiltOnNothingLess.m4a`,
    intervalMs: 1040,
  },
  {
    title: 'Just Pumpkin Day',
    src: `${CDN_URL_BASE}JustPumpkinDay.m4a`,
    intervalMs: 1140,
  },
  {
    title: 'Creme Brulee',
    src: `${CDN_URL_BASE}CremeBrulee.m4a`,
    intervalMs: 1000,
  },
  {
    title: 'Coconut Smoothie',
    src: `${CDN_URL_BASE}CoconutSmoothie.m4a`,
    intervalMs: 1070,
  },
  {
    title: 'Lazy Cat',
    src: `${CDN_URL_BASE}LazyCat.m4a`,
    intervalMs: 748,
  },
  {
    title: 'Curious World',
    src: `${CDN_URL_BASE}CuriousWorld.m4a`,
    intervalMs: 1240,
  },
  {
    title: 'Small Happiness',
    src: `${CDN_URL_BASE}SmallHappiness.m4a`,
    intervalMs: 1000,
  },
  {
    title: 'Chocolate World',
    src: `${CDN_URL_BASE}ChocolateWorld.m4a`,
    intervalMs: 748,
  },
];
