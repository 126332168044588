import { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  // useContractRead,
} from 'wagmi';
import { ethers } from 'ethers';
import ABI from '../../contract.json';
import { useAccount } from 'wagmi';

import backgroundImage from '../../assets/background.jpg';
import mobileBackgroundImage from '../../assets/background_mobile.jpg';
import outerPocketImage from '../../assets/outer_pocket.png';
import catImage from '../../assets/cat.png';
import witchImage from '../../assets/witch_peekaboo.png';
import flyingWitchImage from '../../assets/flyingwitchshadow.png';
import mintButtonImage from './mintbutton.png';
import {
  BACKGROUND_COLOR,
  // contractConfig,
  PC_CONTRACT_ADDRESS
} from '../../constants';
import { TabletStyles } from '../../helpers';

import { ArrowIcon } from '../icons/ArrowIcon';
import { MintBox } from '../MintBox';

const POCKET_WITCH_X = -4;
const POCKET_WITCH_Y = -142;
const POCKET_WITCH_Y_MOBILE = -178;
const CAT_X = 32;
const CAT_X_MOBILE = -78;
const CAT_Y = -11;
const BREAKPOINT = 600;

const SHOW_MINT = true;

const Container = styled.section`
  height: 100vh;
  min-height: 600px;
  background-color: ${BACKGROUND_COLOR};

  ${TabletStyles}
`;

const DrawingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  ${TabletStyles}
`;

const PocketContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 55%;
  transform: translate(-300px, 0px);

  @media screen and (max-width: ${BREAKPOINT}px) {
    transform: translate(0px, 0px);
    width: 100%;
    ${SHOW_MINT ? 'display: none;' : 'display: flex;'}
    justify-content: center;
    top: 50%;
  }
`;

const Witch = styled.img`
  @keyframes witchPeekaboo {
    0% {
      transform: translate(${POCKET_WITCH_X}px, ${POCKET_WITCH_Y + 45}px);
    }
    100% {
      transform: translate(${POCKET_WITCH_X}px, ${POCKET_WITCH_Y}px);
    }
  }

  ${({ witchOffset }) => `
    transform: translate(${POCKET_WITCH_X}px, ${POCKET_WITCH_Y + witchOffset}px);
  `}

  animation: 1400ms ease 0s 1 witchPeekaboo;
  position: absolute;
  width: 255px;
  z-index: 101;

  @media screen and (max-width: ${BREAKPOINT}px) {
    width: 316px;

    @keyframes witchPeekabooMobile {
      0% {
        transform: translate(${POCKET_WITCH_X}px, ${POCKET_WITCH_Y_MOBILE + 45}px);
      }
      100% {
        transform: translate(${POCKET_WITCH_X}px, ${POCKET_WITCH_Y_MOBILE}px);
      }
    }

    ${({ witchOffset }) => `
      transform: translate(${POCKET_WITCH_X}px, ${POCKET_WITCH_Y_MOBILE + witchOffset}px);
    `}

    animation: 1400ms ease 0s 1 witchPeekabooMobile;
  }
`;

const OuterPocket = styled.img`
  position: absolute;
  width: 250px;
  z-index: 103;

  @media screen and (max-width: ${BREAKPOINT}px) {
    width: 310px;
  }
`;

const Cat = styled.img`
  @keyframes catPeekaboo {
    0% {
      transform: translate(${CAT_X}px, ${CAT_Y + 25}px);
    }
    100% {
      transform: translate(${CAT_X}px, ${CAT_Y}px);
    }
  }

  ${({ catOffset }) => `
    transform: translate(${CAT_X}px, ${CAT_Y + catOffset}px);
  `}

  animation: 1400ms ease 0s 1 catPeekaboo;

  position: absolute;
  width: 60px;
  z-index: 102;

  @media screen and (max-width: ${BREAKPOINT}px) {
    width: 65px;

    @keyframes catPeekabooMobile {
      0% {
        transform: translate(${CAT_X_MOBILE}px, ${CAT_Y + 25}px);
      }
      100% {
        transform: translate(${CAT_X_MOBILE}px, ${CAT_Y}px);
      }
    }

    ${({ catOffset }) => `
      transform: translate(${CAT_X_MOBILE}px, ${CAT_Y + catOffset}px);
    `}

    animation: 1400ms ease 0s 1 catPeekabooMobile;
  }
`;

const ImageContainer = styled.picture`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ScrollDownButton = styled.button`
  border: none;
  border-radius: 50%;
  background: transparent;
  width: 50px;
  height: 50px;
  margin-left: 71px;
  margin-top: -22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FLYING_WIDTH = 300;
const FlyingWitch = styled.img`
  width: ${FLYING_WIDTH}px;
`;

const FlyingWitchContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: 50%;
  transform: translate(200px, 10px);
  width: ${FLYING_WIDTH}px;

  @keyframes flyingWitchVertical {
    0% {
      transform: translate(200px, 10px);
    }
    50% {
      transform: translate(200px, 3px);
    }
    100% {
      transform: translate(200px, 10px);
    }
  }

  animation: 4200ms ease-in-out 800ms infinite flyingWitchVertical;

  @media screen and (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

const MintBoxWrapper = styled.div`
  ${({ disabled }) => disabled ? `
    cursor: default;
  ` : `
    cursor: pointer;
    // font-family: 'Mellisa', Helvetica, Trebuchet MS, sans-serif;
  `}

  position: absolute;
  z-index: 100;
  top: 50%;
  transform: translate(180px, -20px);
  width: 270px;

  @media screen and (max-width: ${BREAKPOINT}px) {
    transform: translate(0px, 0px);
    width: 100%;
    display: flex;
    justify-content: center;
    top: 50%;
    width: 300px;
  }
`;

const MintTitle = styled.div`
${({ disabled }) => disabled ? `
  bottom: 45px;
  color: white;
  font-weight: 500;
  font-size: 34px;
` : `
letter-spacing: 3px;
font-weight: 600;
font-size: 40px;
bottom: 45px;
color:white;
`}


  position: absolute;
  width: 100%;
text-align: center;

`;



export const Homepage = ({ onScrollDownClick, ref }) => {
  const { address } = useAccount();
  const [scrollY, setScrollY] = useState(0);
  const [isPresaleActive, setIsPresaleActive] = useState(false);
  const [isSaleActive, setIsSaleActive] = useState(false);
  const [isMintboxOpen, setIsMintboxOpen] = useState(false);
  const [totalMinted, setTotalMinted] = useState('---');
  const [numMinted, setNumMinted] = useState(0);


  const soldout = totalMinted >= 500;

  useEffect(() => {
    let provider;

    if (window.ethereum) {
      provider = new ethers.providers.Web3Provider(window.ethereum);
    } else {
      // provider = new ethers.providers.AlchemyProvider("goerli");
      provider = new ethers.providers.AlchemyProvider(null, 'fQU6y180n-3YPIT3t0ugVDYOGpkTQzWI');
    }
    const contract = new ethers.Contract(
      PC_CONTRACT_ADDRESS,
      ABI,
      provider
    );
    contract.isPresaleActive().then((response) => {
      setIsPresaleActive(response);
    }).catch(error => {
      console.log('failed to fetch is presale active', error);
    })
    contract.isSaleActive().then((response) => {
      setIsSaleActive(response);
    }).catch(error => {
      console.log('failed to fetch is sale active', error);
    })

    contract.totalSupply().then((response) => {
      setTotalMinted(response.toNumber());
    }).catch(error => {
      console.log('error getting minted supply', error);
    })

    const interval = setInterval(() => {
      if (SHOW_MINT) {
        contract.totalSupply().then((response) => {
          console.log('setting supply')
          setTotalMinted(response.toNumber());
        }).catch(error => {
          console.log('error getting minted supply', error);
        })
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (address) {
      let provider;
      if (window.ethereum) {
        provider = new ethers.providers.Web3Provider(window.ethereum);
      } else {
        // provider = new ethers.providers.AlchemyProvider("goerli");
        provider = new ethers.providers.AlchemyProvider(null, 'fQU6y180n-3YPIT3t0ugVDYOGpkTQzWI');
      }
      const contract = new ethers.Contract(
        PC_CONTRACT_ADDRESS,
        ABI,
        provider
      );
      contract.tokensMintedByAddress(address).then((response) => {
        setNumMinted(response.toNumber());
      }).catch((err) => {
        console.log('error getting num minted', err);
      });
    }
  }, [address]);

  console.log({ isSaleActive, isPresaleActive, totalMinted })

  useEffect(() => {
    const handleScroll = (e) => {
      setScrollY(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const witchOffset = Math.min(scrollY * 0.35, 45);
  const catOffset = Math.min(scrollY * 0.19, 25);

  return (
    <Container id="home" ref={ref}>
      {isMintboxOpen && (
        <MintBox
          isSaleActive={isSaleActive}
          isPresaleActive={isPresaleActive}
          totalMinted={totalMinted}
          setTotalMinted={setTotalMinted}
          close={() => setIsMintboxOpen(false)}
          numMinted={numMinted}
          setNumMinted={setNumMinted}
        />
      )}
      <DrawingContainer>
        <PocketContainer>
            <Witch src={witchImage} witchOffset={witchOffset} />
            <Cat src={catImage} catOffset={catOffset} />
            <OuterPocket src={outerPocketImage} />
        </PocketContainer>
        {SHOW_MINT ? (
          <MintBoxWrapper disabled={soldout} onClick={() => !soldout && setIsMintboxOpen(true)}>
            <MintTitle disabled={soldout}>{soldout ? 'Sold Out!' : 'Mint'}</MintTitle>
            <img style={{width: '100%'}} alt="" src={mintButtonImage} />
          </MintBoxWrapper>
        ) : (
          <FlyingWitchContainer>
            <FlyingWitch alt="" src={flyingWitchImage} />
            <ScrollDownButton onClick={onScrollDownClick} aria-hidden="true">
              <ArrowIcon />
            </ScrollDownButton>
          </FlyingWitchContainer>
        )}
        <ImageContainer>
            <source style={{ height: '100%' }} srcSet={mobileBackgroundImage} media={`(max-width: ${BREAKPOINT}px)`} />
            <img style={{ height: '100%' }} src={backgroundImage} alt="" />
        </ImageContainer>
      </DrawingContainer>
    </Container>
  );
};
