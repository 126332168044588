import styled from 'styled-components';

import { SECONDARY_COLOR, HOVER_COLOR } from '../../constants.js';

const SVG = styled.svg`
  fill: ${SECONDARY_COLOR};
  &:hover {
    fill: ${HOVER_COLOR};
  }

  ${({ size }) => `
    width: ${size}px;
    height: ${size}px;
  `}
`;

export const BaseIcon = ({ size = 30, children }) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    size={size}
  >
    {children}
  </SVG>
);
