import { TWITTER_URL } from '../../constants';

export const FAQ_DATA = [
  {
    question: "What is the collection size and price?",
    answer: "There are 500 WITCHES with a mint price of 0.025e.",
  },
  {
    question: "When is reveal?",
    answer: "October 23rd 5pm PST",
  },
  {
    question: "What is the Witchlist, and how do I get on it?",
    answer: (
      <>
        Witchlist members gain early passage to our mint. All <a href="https://twitter.com/crypto_coven" target="_blank" rel="noreferrer" title="opens twitter in a new tab">Crypto Coven</a> and <a href="https://twitter.com/coven_cats" target="_blank" rel="noreferror noreferrer" title="opens twitter in a new tab">Coven Cats</a> holders are automatically inscribed onto our Witchlist. A snapshot will be taken (date and time TBD).
      </>
    ),
  },
  {
    question: "Where can I ask questions or get support?",
    answer: (
      <>
        Message us on <a href={TWITTER_URL} target="_blank" rel="noreferrer">Twitter</a> and we will get back to you soon!
      </>
    ),
  },
];
