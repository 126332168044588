import styled, { css } from 'styled-components';
import { useState, useEffect } from 'react';

import { NavLink } from './NavLink';
import { SideMenu } from './SideMenu';
import { ConnectButton } from '../ConnectButton';
import { TwitterIcon } from '../icons/TwitterIcon';
import { HamburgerIcon } from '../icons/HamburgerIcon';
import { CloseIcon } from '../icons/CloseIcon';

import titleImage from '../../assets/title.png';
import headerImage from '../../assets/header_segment.png';
import {
  TEXT_COLOR,
  MOBILE_WIDTH,
  HEADER_Z_INDEX,
  MENU_BUTTON_Z_INDEX,
  HEADER_IMAGE_Z_INDEX,
  NAV_LINKS_Z_INDEX,
  TWITTER_URL,
} from '../../constants';

const TOP_OFFSET = 17;
const HEADER_IMAGE_HEIGHT = 96;
const BREAKPOINT = 1000;

const HeaderContainer = styled.header`
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: ${HEADER_Z_INDEX};
    ${({ isMenuExpanded }) => `
      height: ${isMenuExpanded ? '100%' : `${HEADER_IMAGE_HEIGHT}px`};
    `}
`;

const HeaderImage = styled.div`
  position: relative;
  height: ${HEADER_IMAGE_HEIGHT}px;
  width: 100%;
  background-image: url("${headerImage}");
  background-repeat: repeat-x;
  background-size: contain;
  z-index: ${HEADER_IMAGE_Z_INDEX};
`;

const HeaderImageGapFill = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  height: 64px;
  z-index: ${HEADER_IMAGE_Z_INDEX - 1};
`;

const TitleContainer = styled.div`
  position: absolute;
  width: 334px;
  top: -10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 503;

  @media screen and (max-width: 380px) {
     width: 280px;
     top: 0;
  }
`;

const TitleBackground = styled.img`
  width: 100%;
`;

const TitleLink = styled.a`
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
  transform: rotate(-5deg);
  font-family: 'Mellisa', Helvetica, Trebuchet MS, sans-serif;
  font-size: 30pt;
  font-weight: normal;
  padding-top: 6px;
  text-decoration: none;

  color: ${TEXT_COLOR};
  &:hover {
    color: ${TEXT_COLOR};
  }

  @media screen and (max-width: 380px) {
     font-size: 27pt;
  }
`;

const NavContainer = styled.nav`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: ${TOP_OFFSET}px;
  z-index: ${NAV_LINKS_Z_INDEX};

  @media screen and (min-width: ${MOBILE_WIDTH + 1}px) and (max-width: ${BREAKPOINT}px) {

  }

  @media screen and (max-width: ${BREAKPOINT}px) {
     display: none;
  }
`;

const RightButtonSyles = css`
  position: absolute;
  width: 30px;
  height: 30px;
  right: 20px;
  top: ${TOP_OFFSET}px;
`;

const Twitter = styled.a`
  ${RightButtonSyles}
  z-index: ${NAV_LINKS_Z_INDEX};

  @media screen and (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

const Connect = styled.div`
display: none;
@media screen and (min-width: ${BREAKPOINT + 1}px) {
  display: block;
}
`;

const IconButton = styled.button`
  ${RightButtonSyles}
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  z-index: ${MENU_BUTTON_Z_INDEX};

  @media screen and (min-width: ${BREAKPOINT + 1}px) {
    display: ${({ isMenuExpanded }) => isMenuExpanded ? 'block' : 'none'};
  }
`;

export const Header = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  // stop main body from scrolling when side menu is open
  useEffect(() => {
   document.body.style.overflow = isMenuExpanded ? 'hidden' : 'unset';
 }, [isMenuExpanded]);

  return (
    <HeaderContainer isMenuExpanded={isMenuExpanded}>
      <TitleContainer>
          <TitleLink
            href="#home"
            title="jump to the homepage"
            onClick={() => setIsMenuExpanded(false)}
          >
            Pocket Coven
          </TitleLink>
        <TitleBackground src={titleImage} />
      </TitleContainer>
      <HeaderImage />
      <HeaderImageGapFill />
      {!isMenuExpanded && (
        <>
          <NavContainer>
              <NavLink title="about" link="#about" />
              <NavLink title="faq" link="#faq" />
              <NavLink title="team" link="#team" />
              <NavLink title="opensea" link="https://opensea.io/collection/pocket-coven" />
          </NavContainer>
          <Connect>
            <ConnectButton />
          </Connect>
          <Twitter
            href={TWITTER_URL}
            target="_blank"
            title="open twitter in a new tab"
            isMenuExpanded={isMenuExpanded}
          >
            <TwitterIcon />
          </Twitter>
        </>
      )}
      <IconButton
        title={isMenuExpanded ? 'close side panel' : 'open side panel'}
        onClick={() => setIsMenuExpanded(!isMenuExpanded)}
        isMenuExpanded={isMenuExpanded}
      >
        {isMenuExpanded ? <CloseIcon /> : <HamburgerIcon />}
      </IconButton>
      <SideMenu isExpanded={isMenuExpanded} onClose={() => setIsMenuExpanded(false)} />
    </HeaderContainer>
  );
};
