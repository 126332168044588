import styled from 'styled-components';

import { BaseIcon } from './BaseIcon';

const Wrapper = styled.div`
  margin-top: -10px;
`;

export const MinusIcon = ({ size }) => (
  <Wrapper>
    <BaseIcon size={size}>
      <path d="M7 19h10c.55 0 1 .45 1 1s-.45 1-1 1H7c-.55 0-1-.45-1-1s.45-1 1-1z"/>
    </BaseIcon>
  </Wrapper>
);
