import styled from 'styled-components';

import { PlayIcon } from '../icons/PlayIcon';
import { SoundIcon } from '../icons/SoundIcon';
import { PauseIcon } from '../icons/PauseIcon';
import { MuteIcon } from '../icons/MuteIcon';
import { NextIcon } from '../icons/NextIcon';
import { PrevIcon } from '../icons/PrevIcon';

const COLLAPSED_BUTTON_SIZE = 30;
const EXPANDED_BUTTON_SIZE = 30;

const Container = styled.div`
  margin-right: 20px;
  margin-left: 7px;
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  overflow: hidden;

  ${({ size, isHidden }) => `
    width: ${isHidden ? 0 : (size || EXPANDED_BUTTON_SIZE)}px;
    height: ${isHidden ? 0 : (size || EXPANDED_BUTTON_SIZE)}px;
  `}

  will-change: width;
  transition: width 200ms ease;
`;

export const ExpandedControls = ({
  onPrevClick,
  onPlayPauseClick,
  onNextClick,
  isPlaying,
  isExpanded,
}) => (
  <Container>
      <Button
        onClick={onPrevClick}
        title="play previous song"
        isHidden={!isExpanded}
      >
        <PrevIcon />
      </Button>
    <Button
      onClick={onPlayPauseClick}
      title={isPlaying ? 'pause music' : 'play music'}
      size={isExpanded ? EXPANDED_BUTTON_SIZE : COLLAPSED_BUTTON_SIZE}
    >
      {isExpanded ? (
        isPlaying ? <PauseIcon /> : <PlayIcon />
      ) : (
        isPlaying ? <SoundIcon size={COLLAPSED_BUTTON_SIZE}/> : <MuteIcon size={COLLAPSED_BUTTON_SIZE} />
      )}
    </Button>
    <Button
      onClick={onNextClick}
      title="play next song"
      isHidden={!isExpanded}
    >
      <NextIcon />
    </Button>
  </Container>
);
