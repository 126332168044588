// TODO read from contract
import ABI from './contract.json';

export const PC_CONTRACT_ADDRESS = '0xa04eff0578d6f2d69477d048d4295808638ad0dd';

export const contractConfig = {
  address: PC_CONTRACT_ADDRESS,
  abi: ABI,
};

// colors
export const TEXT_COLOR = 'black';
export const HOVER_COLOR = '#616161';
export const SECONDARY_COLOR = '#474247';
export const BACKGROUND_COLOR = '#d5a1a6';
export const TEAM_BACKGROUND_COLOR = '#fbf4fb';

export const TABLET_WIDTH = 550;
export const MOBILE_WIDTH = 400;
export const TABLET_HEIGHT = 768;
export const TABLET_SECTION_HEIGHT = 800;

// z-indexes
export const MENU_Z_INDEX = 500;
export const MENU_BUTTON_Z_INDEX = 600;
export const HEADER_Z_INDEX = 501;
export const HEADER_IMAGE_Z_INDEX = 502;
export const NAV_LINKS_Z_INDEX = 503;
export const MUSIC_Z_INDEX = 600;

export const TWITTER_URL = 'https://twitter.com/pocket_coven';

export const CDN_URL_BASE = 'https://pocketcoven.s3.us-east-1.amazonaws.com/';

export const CAULDRON_IMAGE = `${CDN_URL_BASE}cauldron.png`;
export const COVEN_PEEK_IMAGE = `${CDN_URL_BASE}optimized/coven_peek.png`;
export const JOJO_IMAGE = `${CDN_URL_BASE}optimized/jojo.jpg`;
export const LADYSILK_IMAGE = `${CDN_URL_BASE}optimized/ladysilk.jpg`;
export const YELOW_IMAGE = `${CDN_URL_BASE}optimized/yelow.jpg`;
export const MADAGREY_IMAGE = `${CDN_URL_BASE}optimized/madagrey.jpeg`;
export const MAGE_VID = `${CDN_URL_BASE}magevid_small.mp4`;
export const MAGE_VID_POSTER = `${CDN_URL_BASE}optimized/magevid_poster.jpg`;
export const PIANO_PAUSED_IMAGE = `${CDN_URL_BASE}optimized/piano_paused.png`;
export const PIANO_PLAYING_A_IMAGE = `${CDN_URL_BASE}optimized/piano_playing_a.png`;
export const PIANO_PLAYING_B_IMAGE = `${CDN_URL_BASE}optimized/piano_playing_b.png`;
export const PIANO_PLAYING_C_IMAGE = `${CDN_URL_BASE}optimized/piano_playing_c.png`;
export const SNEAK_1_IMAGE = `${CDN_URL_BASE}optimized/sneak1.jpg`;
export const SNEAK_2_IMAGE = `${CDN_URL_BASE}optimized/sneak2.jpg`;
export const SNEAK_3_IMAGE = `${CDN_URL_BASE}optimized/sneak3.jpg`;
export const ROADMAP_IMAGE = `${CDN_URL_BASE}optimized/roadmap.jpg`;
export const ROADMAP_MOBILE_IMAGE = `${CDN_URL_BASE}optimized/roadmap_mobile.jpg`;
