import styled from 'styled-components';

import { Title } from '../Title';
import { TeamMember, BREAKPOINT } from './TeamMember';
import {
  TEAM_BACKGROUND_COLOR,
  LADYSILK_IMAGE,
  JOJO_IMAGE,
  YELOW_IMAGE,
  MADAGREY_IMAGE,
} from '../../constants.js';

const Container = styled.section`
  background: ${TEAM_BACKGROUND_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 20px;
  // box-shadow: 0 0 20px 0px rgba(0 0 0 / 20%);
  // clip-path: polygon(0% 0%, 100% 0%, 100% 120%, 0% 120%);
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`;

const TitleContainer = styled.div`
  margin-left: -85px;
  padding-top: 60px;

  @media screen and (max-width: ${BREAKPOINT}px) {
    margin-left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 70px;
  }
`;

export const Team = () => (
  <Container id="team">
    <InnerContainer>
      <TitleContainer>
        <Title title="team" />
      </TitleContainer>
      <TeamMember
        image={JOJO_IMAGE}
        name="jojochuu"
        role="founder | artist | web design & dev"
        description="JoJo was formerly a senior software engineer with 6 years of experience in big tech. She departed from web2 to explore her passions in art and web3, which led her to crafting Pocket Coven."
        twitterLink="https://twitter.com/jojochuu"
      />
      <TeamMember
        image={LADYSILK_IMAGE}
        name="lady silk"
        role="code conjuror"
        description="Lady Silk is a professional software engineer working on design tools who's also a published artist in photography. In their spare time, they like to lift weights, learn about art, and experiment in web3."
        twitterLink="https://twitter.com/i_like_calculus"
      />
      <TeamMember
        image={YELOW_IMAGE}
        name="yelow"
        role="strategic advisor"
        description="Yelow is a web3 professional, working as an advisor for WestCoastNFT. He also has experience in project management and civil engineering. Outside of web3, he is an active DJ and music producer."
        twitterLink="https://twitter.com/yelownft"
      />
      <TeamMember
        image={MADAGREY_IMAGE}
        name="madagrey"
        role="engineering advisor"
        description="Madagrey is a product manager and former software engineer in big tech who also enjoys generative art, anime, and strategy games."
        twitterLink="https://twitter.com/madagreye"
      />
    </InnerContainer>
  </Container>
);
