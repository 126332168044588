import { css } from 'styled-components';

import {
  TABLET_HEIGHT,
  TABLET_WIDTH,
  TABLET_SECTION_HEIGHT,
} from './constants';

export const TabletStyles = css`
  @media screen and (min-height: ${TABLET_HEIGHT}px) and (min-width: ${TABLET_WIDTH}px) {
    height: ${TABLET_SECTION_HEIGHT}px;
    min-height: ${TABLET_SECTION_HEIGHT}px;
  }
`;
