import { useState } from 'react';

import styled from 'styled-components';

import { Title } from '../Title';
import { FAQBox, SIDE_PADDING } from './FAQBox';
import { FAQ_DATA } from './constants';
import { TEAM_BACKGROUND_COLOR, MOBILE_WIDTH, COVEN_PEEK_IMAGE } from '../../constants.js';

const SectionContainer = styled.section`
  background: #ffeee6;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-shadow: 0 0 20px 0px rgba(0 0 0 / 20%);
`;

const FAQsContainer = styled.div`
  margin: 20px 30px 30px 30px;

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    margin: 30px 20px;
  }
`;

const Answer = styled.p`
  margin: 0;
  padding: 0 ${SIDE_PADDING}px 30px ${SIDE_PADDING}px;
`;

const CovenPeekContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const PeekBackground = styled.div`
  background: ${TEAM_BACKGROUND_COLOR};
  height: 500px;
  width: 100%;
  position: absolute;
  bottom: -486px;
  z-index: 0;
  box-shadow: 0 0 20px 0px rgb(0 0 0 / 20%);
`;

const CovenPeek = styled.img`
  max-width: 450px;
  width: 100%;
  z-index: 100;
`;

export const FAQ = () => {
  const [expandedIndexes, setExpandedIndexes] = useState(new Set());

  const handleClick = (clickedIndex) => {
      setExpandedIndexes(prevSet => {
        const newSet = new Set(prevSet);
        if (newSet.has(clickedIndex)) {
          newSet.delete(clickedIndex);
        } else {
          newSet.add(clickedIndex);
        }
        return newSet;
      });
  };

  return (
    <SectionContainer id="faq">
      <Title title="FAQ" topPadding={100} size={33} />
      <FAQsContainer>
        {FAQ_DATA.map((faq, index) => (
          <FAQBox
            key={faq.question}
            question={faq.question}
            answer={
              <Answer>
                {faq.answer}
              </Answer>
            }
            onClick={() => handleClick(index)}
            isExpanded={expandedIndexes.has(index)}
          />
        ))}
      </FAQsContainer>
      <CovenPeekContainer>
        <CovenPeek src={COVEN_PEEK_IMAGE} />
        <PeekBackground />
      </CovenPeekContainer>
    </SectionContainer>
  );
};
