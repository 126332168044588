import { useRef } from 'react';

import { WagmiConfig, createClient } from "wagmi";
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import { Buffer } from "buffer";

import { Header } from './components/Header';
import { Homepage } from './components/Homepage';
import { About } from './components/About';
import { FAQ } from './components/FAQ';
import { Team } from './components/Team';
import { MusicPlayer } from './components/MusicPlayer';
import { Footer } from './components/Footer';

// const ALCHEMY_BASE_URL = 'https://eth-mainnet.g.alchemy.com/v2/';

// const alchemyId = process.env.ALCHEMY_RINKEBY_ID; // TODO use prod key for mainnet

if (!window.Buffer) window.Buffer = Buffer;

const client = createClient(
  getDefaultClient({
    appName: "Pocket Coven",
  }),
);

const App = () => {
  const aboutRef = useRef(null);

  const scrollToAbout = () => {
    if (aboutRef && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const isIOS = [
    // 'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    // 'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform);

  return (
    <WagmiConfig client={client}>
      <ConnectKitProvider>
        <Header />
        {!isIOS && <MusicPlayer />}
        <Homepage onScrollDownClick={scrollToAbout} />
        <About innerRef={aboutRef} />
        <FAQ />
        <Team />
        <Footer />
      </ConnectKitProvider>
    </WagmiConfig>
  );
};

export default App;
