import styled from 'styled-components';

import { TwitterIcon } from '../icons/TwitterIcon';
import { SECONDARY_COLOR } from '../../constants';

export const BREAKPOINT = 600;

const Container = styled.div`
  width: 100%;
  max-width: 700px;
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 10px;
  }
`;

const Image = styled.img`
  width: 230px;
  height: 230px;
  border-radius: 20px;
  border: 3px solid ${SECONDARY_COLOR};
  margin-right: 30px;

  @media screen and (max-width: ${BREAKPOINT}px) {
    margin-right: 0;
  }
`;

const Information = styled.div`
  font-family: mr-eaves-modern, sans-serif;

  @media screen and (max-width: ${BREAKPOINT}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const NameRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0 0 0;
`;

const Name = styled.h3`
  font-weight: 500;
  font-size: 21pt;
  margin: 0;
  letter-spacing: -1px;

  @media screen and (max-width: ${BREAKPOINT}px) {
    font-size: 22pt;
  }
`;

const Role = styled.div`
  font-weight: 500;
  font-size: 16pt;
  margin-bottom: 14px;
`;

const Description = styled.p`
  padding: 0;
  margin: 0;
  font-weight: 300;
  font-size: 16pt;

  @media screen and (max-width: ${BREAKPOINT}px) {
    max-width: 450px;
  }
`;

const Twitter = styled.a`
  margin-left: 9px;
  margin-top: 6px;
`;

export const TeamMember = ({
  image,
  name,
  role,
  description,
  twitterLink,
}) => (
  <Container>
    <Image src={image} loading="lazy" />
    <Information>
      <NameRow>
        <Name>{name}</Name>
        <Twitter href={twitterLink} target="_blank" title="open twitter in a new tab">
          <TwitterIcon size={18}/>
        </Twitter>
      </NameRow>
      <Role>{role}</Role>
      <Description>{description}</Description>
    </Information>
  </Container>
);
