import styled from 'styled-components';

import { ConnectKitButton } from "connectkit";

import { TEXT_COLOR, SECONDARY_COLOR } from '../../constants';

const Button = styled.button`
  position: absolute;
  top: 14px;
  right: 73px;
  z-index: 503;
  width: 160px;
  height: 40px;
  border: 1px solid ${TEXT_COLOR};
  border-radius: 5px;

  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 14px;
`;

const MintBoxDesign = styled.button`
font-family: mr-eaves-modern, sans-serif;
font-weight: 500 !important;
font-size: 20pt !important;
margin-top: 20px;
  border: ${SECONDARY_COLOR} solid 2px;
  border-radius: 15px;
  background: #ffe6e6;

  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px 30px;
`;

export const ConnectButton = ({ showMintboxDesign }) => (
  <ConnectKitButton.Custom>
    {({ isConnected, isConnecting, show, hide, address, ensName }) => {
      return (
        showMintboxDesign ? (
          <MintBoxDesign onClick={show}>
            {isConnected ? address : "Connect Wallet"}
          </MintBoxDesign>
        ) : (
          <Button onClick={show}>
            {isConnected ? address : "Connect Wallet"}
          </Button>
        )
      );
    }}
  </ConnectKitButton.Custom>
);
