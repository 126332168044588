import styled from 'styled-components';

const TitleText = styled.h2`
  margin: 0;
  font-family: 'Mellisa', Helvetica, Trebuchet MS, sans-serif;
  font-size: ${({ size }) => `${size}pt;`}
  font-weight: normal;
  padding-top: ${({ topPadding }) => `${topPadding}px;`}
  line-height: 1.8;
`;

export const Title = ({ title, size = 36, topPadding = 0 }) => (
  <TitleText
    size={size}
    topPadding={topPadding}
  >
    {title}
  </TitleText>
);
